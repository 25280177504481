import React from 'react'
import { Edit, SimpleForm, TextInput, BooleanInput } from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import AlertsInput from './Alerts'

const MerchantOpsSettingsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <AutocompleteReferenceInput
        source='merchant_id'
        reference='merchant'
        optionText='name'
        label='Merchant'
      />
      <AlertsInput source='alerts' />
      <TextInput source='meta' />
      <TextInput source='ops_notes' multiline />
      <BooleanInput source='is_fragile' label='Fragile?' />
      <TextInput source='alerts_slack_channel' />
    </SimpleForm>
  </Edit>
)

export default MerchantOpsSettingsEdit
