import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ImageField
} from 'react-admin'
import ListActions from '../common/ListActions'

const AppList = (props) => (
  <List {...props} actions={<ListActions {...props} />}>
    <Datagrid rowClick='edit'>
      <TextField source='id' />
      <ReferenceField source='organization_id' reference='organization'>
        <TextField source='name' />
      </ReferenceField>
      <TextField source='name' />
      <ImageField source='logo_url' />
      <DateField source='created_at' />
      <DateField source='updated_at' />
    </Datagrid>
  </List>
)

export default AppList
