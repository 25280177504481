import { map, ifElse, type, equals, pipe, identity } from 'ramda'
import convertArrayToVectorType from './convertArrayToVectorType'

const normaliseValue = ifElse(
  pipe(type, equals('Array')),
  convertArrayToVectorType,
  identity
)

export default (data) => map(normaliseValue, data)
