import React from 'react'
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin'
import AlertsInput from './Alerts'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'

const MerchantOpsSettingsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <AutocompleteReferenceInput
        source='merchant_id'
        reference='merchant'
        optionText='name'
        label='Merchant'
      />
      <AlertsInput source='alerts' />
      <TextInput source='meta' />
      <TextInput source='ops_notes' multiline />
      <BooleanInput source='is_fragile' label='Fragile?' />
      <TextInput source='alerts_slack_channel' />
    </SimpleForm>
  </Create>
)

export default MerchantOpsSettingsCreate
