import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  BooleanField,
  BooleanInput,
  Filter
} from 'react-admin'
import VectorField from '../VectorField'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import ListActions from '../common/ListActions'

const MerchantOpsSettingsList = (props) => (
  <List {...props} filters={<Filters />} actions={<ListActions {...props} />}>
    <Datagrid rowClick='edit'>
      <ReferenceField source='merchant_id' reference='merchant'>
        <TextField source='name' />
      </ReferenceField>
      <VectorField source='alerts' label='Alerts' />
      <TextField source='meta' />
      <TextField source='ops_notes' />
      <BooleanField source='is_fragile' label='Fragile?' />
      <TextField source='alerts_slack_channel' />
      <DateField source='created_at' />
      <DateField source='updated_at' />
    </Datagrid>
  </List>
)

export const Filters = (props) => (
  <Filter {...props}>
    <AutocompleteReferenceInput
      label='Search by Merchant'
      source='merchant_id'
      reference='merchant'
      optionText='name'
      allowEmpty
      alwaysOn
    />
    <BooleanInput source='is_fragile' label='Fragile?' alwaysOn />
  </Filter>
)

export default MerchantOpsSettingsList
