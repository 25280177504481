import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  ReferenceField
} from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import ListActions from '../common/ListActions'

const UserMerchantRolesList = (props) => (
  <List {...props} filters={<Filters />} actions={<ListActions {...props} />}>
    <Datagrid rowClick='edit'>
      <TextField source='role' />
      <DateField source='updated_at' />
      <DateField source='created_at' />
      <ReferenceField source='merchant_id' reference='merchant'>
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField source='user_id' reference='user'>
        <TextField source='username' />
      </ReferenceField>
    </Datagrid>
  </List>
)

const Filters = (props) => (
  <Filter {...props}>
    <AutocompleteReferenceInput
      label='Search by Username'
      source='user_id'
      reference='user'
      optionText='username'
      allowEmpty
      alwaysOn
    />
    <AutocompleteReferenceInput
      label='Search by Merchant name'
      source='merchant_id'
      reference='merchant'
      optionText='name'
      allowEmpty
      alwaysOn
    />
  </Filter>
)

export default UserMerchantRolesList
