import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

export default () => (
  <Card>
    <CardHeader title='Welcome to the administration' />
    <CardContent>Brainstorm and put some useful stats here</CardContent>
  </Card>
)
