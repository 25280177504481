import React from 'react'
import { List, Datagrid, BooleanField, DateField, TextField } from 'react-admin'

export default (props) => (
  <List {...props} actions={null}>
    <Datagrid rowClick='edit'>
      <TextField source='feature' />
      <BooleanField source='active' label='Active' />
      <DateField source='created_at' />
      <DateField source='updated_at' />
    </Datagrid>
  </List>
)
