import React from 'react'
import { Create, SimpleForm } from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import RolesInput from '../RolesInput'

const UserOrganizationRoleCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <AutocompleteReferenceInput
        source='organization_id'
        reference='organization'
        optionText='name'
        label='Organization'
      />
      <AutocompleteReferenceInput
        source='user_id'
        reference='user'
        optionText='username'
        label='Username'
      />
      <RolesInput />
    </SimpleForm>
  </Create>
)

export default UserOrganizationRoleCreate
