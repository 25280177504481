import backendApi from '../backendApiProvider'

export default {
  // called when the user attempts to log in
  login: async ({ email, password }) => {
    const { token } = await backendApi.login(email, password)
    localStorage.setItem('accessToken', token)
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('accessToken')
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('secret')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const token = localStorage.getItem('accessToken')

    if (!token) {
      return Promise.reject()
    }

    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve()
}
