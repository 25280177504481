import React from 'react'
import PropTypes from 'prop-types'
import { SelectInput } from 'react-admin'

const vehicleTypes = [
  'bicycle',
  'bicycle_cargo',
  'car',
  'motorcycle',
  'van',
  'van_small',
  'walk'
]

export const choices = vehicleTypes.map((vehicleType) => ({
  id: vehicleType,
  name: vehicleType
}))

const VehicleTypeInput = ({ source }) => (
  <SelectInput source={source} choices={choices} />
)

VehicleTypeInput.propTypes = {
  source: PropTypes.string.isRequired
}

export default VehicleTypeInput
