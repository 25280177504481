import React from 'react'
import PropTypes from 'prop-types'
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  required
} from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import validateExists from '../../validators/validateExists'
import ImageUploadInput from '../common/ImageUploadInput'

const MerchantEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source='short_reference' />
      <AutocompleteReferenceInput
        source='organization_id'
        reference='organization'
        optionText='name'
        label='Organization'
      />
      <BooleanInput source='active' />
      <TextInput
        source='name'
        validate={[required(), validateExists(props, 'name')]}
      />
      <TextInput
        source='api_name'
        validate={[required(), validateExists(props, 'api_name')]}
      />
      <ImageUploadInput source='logo_url' label='Logo' />
      <BooleanInput source='allow_advanced_auto_booking' />
    </SimpleForm>
  </Edit>
)

MerchantEdit.propTypes = { resource: PropTypes.string.isRequired }

export default MerchantEdit
