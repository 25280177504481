import React from 'react'
import { Create, SimpleForm } from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import RolesInput from '../RolesInput'

const UserMerchantRolesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <AutocompleteReferenceInput
        source='merchant_id'
        reference='merchant'
        optionText='name'
        label='Merchant'
      />
      <AutocompleteReferenceInput
        source='user_id'
        reference='user'
        optionText='username'
        label='Username'
      />
      <RolesInput />
    </SimpleForm>
  </Create>
)

export default UserMerchantRolesCreate
