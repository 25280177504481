import React from 'react'
import PropTypes from 'prop-types'
import { SelectInput } from 'react-admin'

const serviceTypes = ['direct', 'regular']

export const choices = serviceTypes.map((vehicleType) => ({
  id: vehicleType,
  name: vehicleType
}))

const ServiceTypeInput = ({ source }) => (
  <SelectInput source={source} choices={choices} />
)

ServiceTypeInput.propTypes = {
  source: PropTypes.string.isRequired
}

export default ServiceTypeInput
