import React from 'react'
import { ReferenceInput, AutocompleteInput } from 'react-admin'
import PropTypes from 'prop-types'

const AutocompleteReferenceInput = ({
  source,
  reference,
  optionText,
  label,
  validate,
  filter = {}
}) => {
  const filterToQuery = (search) => {
    if (!search) {
      return {}
    }
    return { [optionText]: { _ilike: `%${search}%` } }
  }

  return (
    <ReferenceInput
      source={source}
      reference={reference}
      filter={filter}
      filterToQuery={filterToQuery}
      label={label || source}
      validate={validate}
    >
      <AutocompleteInput optionText={optionText} />
    </ReferenceInput>
  )
}

AutocompleteReferenceInput.propTypes = {
  source: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  optionText: PropTypes.string.isRequired,
  label: PropTypes.string,
  validate: PropTypes.arrayOf(PropTypes.func),
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.object
}

AutocompleteReferenceInput.defaultProps = {
  label: '',
  validate: [],
  filter: {}
}

export default AutocompleteReferenceInput
