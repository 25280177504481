import React from 'react'
import { SelectArrayInput } from 'react-admin'

export const choices = [
  { id: 'clone_task', name: 'Clone Task' },
  { id: 'cancel_task', name: 'Cancel Task' },
  { id: 'edit_task', name: 'Edit Task' },
  { id: 'move_task', name: 'Move Task' },
  { id: 'see_eta', name: 'See ETA' },
  { id: 'superuser', name: 'Super User' },
  { id: 'organization_admin', name: 'Organization Admin' }
]

const FeaturesInput = () => (
  <SelectArrayInput source='features' choices={choices} />
)

export default FeaturesInput
