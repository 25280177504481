const url = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000'
const apiGatewayUrl =
  process.env.REACT_APP_API_GATEWAY_URL || 'http://localhost:3002'

const request = async (method, path, body) => {
  const resp = await fetch(`${url}${path}`, {
    method,
    headers: {
      authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'content-type': 'application/json'
    },
    body: body && JSON.stringify(body)
  })
  const respBody = await resp.json()

  if (resp.status >= 400) {
    throw new Error(respBody.message || 'Unknown error')
  }

  return respBody
}

export default {
  setPassword(userId, password) {
    return request('put', `/users/${userId}/set-password`, { password })
  },
  login(email, password) {
    return request('post', '/users/login', {
      email,
      password
    }).then((resp) => ({ token: resp.token }))
  },
  async uploadImage(file) {
    const formData = new FormData()
    formData.append('image', file)

    const resp = await fetch(`${url}/upload`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      body: formData
    })

    const data = await resp.json()

    if (resp.status !== 200) {
      throw new Error(data.message)
    }

    return data.url
  },
  createFleetCommand(params) {
    fetch(`${apiGatewayUrl}/api/v1/fleets`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'content-type': 'application/json'
      },
      body: params && JSON.stringify(params)
    })
  },
  editFleetCommand(params) {
    fetch(`${apiGatewayUrl}/api/v1/fleets/${params.fleetId}`, {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'content-type': 'application/json'
      },
      body: params && JSON.stringify(params)
    })
  },
  activateFleetCommand(id) {
    fetch(`${apiGatewayUrl}/api/v1/fleets/${id}/activate`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'content-type': 'application/json'
      },
      body: id && JSON.stringify({ fleetId: id, author: { name: 'admin' } })
    })
  },
  deactivateFleetCommand(id) {
    fetch(`${apiGatewayUrl}/api/v1/fleets/${id}/de-activate`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'content-type': 'application/json'
      },
      body: id && JSON.stringify({ fleetId: id, author: { name: 'admin' } })
    })
  },
  changeAllowChargeableOperations(id, allowChargeableOperations) {
    return fetch(`${url}/organizations/${id}/allow-chargeable-operations`, {
      method: 'PATCH',
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'content-type': 'application/json'
      },
      body: JSON.stringify({ id, allowChargeableOperations })
    })
  }
}
