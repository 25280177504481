import React from 'react'
import { Create, SimpleForm, TextInput, required } from 'react-admin'
import FeaturesInput from './Features'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import validateExists from '../../validators/validateExists'

const CreateUser = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='username'
        validate={[required(), validateExists(props, 'username')]}
      />
      <TextInput
        source='email'
        validate={[required(), validateExists(props, 'email')]}
        type='email'
      />
      <FeaturesInput />
      <AutocompleteReferenceInput
        source='organization_id'
        reference='organization'
        optionText='name'
        label='Organization'
      />
    </SimpleForm>
  </Create>
)

export default CreateUser
