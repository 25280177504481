import React from 'react'
import {
  List,
  Datagrid,
  BooleanField,
  TextField,
  DateField,
  Filter,
  ImageField
} from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import ListActions from '../common/ListActions'

const OrganizationList = (props) => (
  <List {...props} filters={<Filters />} actions={<ListActions {...props} />}>
    <Datagrid rowClick='edit'>
      <TextField source='id' />
      <TextField source='name' />
      <ImageField source='logo_url' label='logo' />
      <TextField source='meta' />
      <TextField source='meta' />
      <BooleanField
        source='enable_fleet_integration'
        label='Enable Fleet Integration'
      />
      <BooleanField
        source='allow_chargable_operations'
        label='Allow chargable operations'
      />
      <DateField source='created_at' />
    </Datagrid>
  </List>
)

export const Filters = (props) => (
  <Filter {...props}>
    <AutocompleteReferenceInput
      label='Search by Organization'
      source='id'
      reference='organization'
      optionText='name'
      allowEmpty
      alwaysOn
    />
  </Filter>
)
export default OrganizationList
