/* eslint-disable */
import { omit } from 'ramda'
import {
  bulkQuery,
  selectQuery,
  countQuery,
  insertQuery,
  updateQuery,
  deleteQuery
} from './queries'
import BackendApi from '../backendApiProvider'
import normaliseRequestData from './normaliseRequestData'
import { isEqual, get } from 'lodash'
import backendApiProvider from '../backendApiProvider'

const DEFAULT_PRIMARY_KEY = 'id'

const cloneQuery = (query) => {
  return JSON.parse(JSON.stringify(query))
}

export default (serverEndpoint, httpClient, config) => {
  const getTableSchema = (resource) => {
    let tableName
    let schema

    // parse schema in resource
    if (resource && resource.split('.').length === 1) {
      schema = 'public'
      tableName = resource
    } else if (resource && resource.split('.').length === 2) {
      const resourceSplit = resource.split('.')

      schema = resourceSplit[0]
      tableName = resourceSplit[1]
    } else {
      throw new Error(
        JSON.stringify({ error: 'Invalid table/schema resource' })
      )
    }
    return { schema, tableName }
  }

  const getPrimaryKey = (resource) => {
    let primaryKey = DEFAULT_PRIMARY_KEY

    if (config && config['primaryKey'][resource]) {
      primaryKey = config['primaryKey'][resource]
    }
    return primaryKey
  }

  const addFilters = (where, filter) => {
    if (!filter) return where

    const filterKeys = Object.keys(filter)

    if (filterKeys.length === 0) return where

    const whereCopy = Object.assign(where)

    filterKeys.forEach((key) => {
      whereCopy[key] = filter[key]
    })
    return whereCopy
  }

  const convertDataRequestToHTTP = (type, resource, params) => {
    const options = {}
    let finalQuery = {}

    const tableSchema = getTableSchema(resource)

    if (params.data) {
      params.data = normaliseRequestData(params.data)
    }

    let { schema, tableName } = tableSchema
    const primaryKey = getPrimaryKey(resource)

    if (DEFAULT_PRIMARY_KEY != primaryKey && params.data && params.data.id) {
      params.data = omit([DEFAULT_PRIMARY_KEY], params.data)
    }

    switch (type) {
      case 'GET_LIST':
        // select multiple
        const finalSelectQuery = cloneQuery(selectQuery)
        const finalCountQuery = cloneQuery(countQuery)

        finalSelectQuery.args.table = { name: tableName, schema: schema }
        finalSelectQuery.args.limit = params.pagination.perPage
        finalSelectQuery.args.offset =
          params.pagination.page * params.pagination.perPage -
          params.pagination.perPage
        finalSelectQuery.args.where = params.filter
        finalSelectQuery.args.order_by = {
          column: primaryKey,
          type:
            typeof params.sort.order === 'undefined'
              ? 'asc'
              : params.sort.order.toLowerCase()
        }
        finalCountQuery.args.table = { name: tableName, schema: schema }
        finalCountQuery.args.where = {}
        finalCountQuery.args.where[primaryKey] = { $ne: null }
        finalCountQuery.args.where = addFilters(
          finalCountQuery.args.where,
          params.filter
        )
        finalQuery = cloneQuery(bulkQuery)
        finalQuery.args.push(finalSelectQuery)
        finalQuery.args.push(finalCountQuery)
        break
      case 'GET_ONE':
        // select one
        finalQuery = cloneQuery(selectQuery)
        finalQuery.args.table = { name: tableName, schema: schema }
        finalQuery.args.where = {}
        for (const key of Object.keys(params)) {
          finalQuery.args.where[key === 'id' ? primaryKey : key] = {
            $eq: params[key]
          }
        }
        break
      case 'CREATE':
        // create one
        const createFields = Object.keys(params.data)

        finalQuery = cloneQuery(insertQuery)
        finalQuery.args.table = { name: tableName, schema: schema }
        finalQuery.args.objects.push(params.data)
        createFields.push(primaryKey)
        finalQuery.args.returning = createFields
        break
      case 'UPDATE':
        // update one
        const updateFields = Object.keys(params.data)

        finalQuery = cloneQuery(updateQuery)
        finalQuery.args.table = { name: tableName, schema: schema }
        finalQuery.args['$set'] = params.data
        finalQuery.args.where = {}
        finalQuery.args.where[primaryKey] = { $eq: params.id }
        updateFields.push(primaryKey)
        finalQuery.args.returning = updateFields

        break
      case 'UPDATE_MANY':
        // update multiple ids with given data
        const updateManyFields = Object.keys(params.data)

        finalQuery = cloneQuery(updateQuery)
        finalQuery.args.table = { name: tableName, schema: schema }
        finalQuery.args['$set'] = params.data
        finalQuery.args.where = {}
        finalQuery.args.where[primaryKey] = { $in: params.ids }
        updateManyFields.push(primaryKey)
        finalQuery.args.returning = updateManyFields
        break
      case 'DELETE':
        // delete one
        const deleteFields = Object.keys(params.previousData)

        finalQuery = cloneQuery(deleteQuery)
        finalQuery.args.table = { name: tableName, schema: schema }
        finalQuery.args.where = {}
        finalQuery.args.where[primaryKey] = { $eq: params.id }
        deleteFields.push(primaryKey)
        finalQuery.args.returning = deleteFields
        break
      case 'DELETE_MANY':
        // delete multiple
        finalQuery = cloneQuery(deleteQuery)
        finalQuery.args.table = { name: tableName, schema: schema }
        finalQuery.args.where = {}
        finalQuery.args.where[primaryKey] = { $in: params.ids }
        finalQuery.args.returning = [primaryKey]
        break
      case 'GET_MANY':
        // select multiple within where clause
        const finalManyQuery = cloneQuery(selectQuery)
        const finalManyCountQuery = cloneQuery(countQuery)

        finalManyQuery.args.table = { name: tableName, schema: schema }
        finalManyQuery.args.where = {}
        finalManyQuery.args.where[primaryKey] = { $in: params.ids }
        finalManyQuery.args.where = addFilters(
          finalManyQuery.args.where,
          params.filter
        )

        finalManyCountQuery.args.table = { name: tableName, schema: schema }
        finalManyCountQuery.args.where = {}
        finalManyCountQuery.args.where[primaryKey] = { $ne: null }
        finalManyCountQuery.args.where = addFilters(
          finalManyCountQuery.args.where,
          params.filter
        )

        finalQuery = cloneQuery(bulkQuery)
        finalQuery.args.push(finalManyQuery)
        finalQuery.args.push(finalManyCountQuery)
        break
      case 'GET_MANY_REFERENCE':
        // select multiple with relations
        const finalManyRefQuery = cloneQuery(selectQuery)
        const finalManyRefCountQuery = cloneQuery(countQuery)

        finalManyRefQuery.args.table = { name: tableName, schema: schema }
        finalManyRefQuery.args.limit = params.pagination.perPage
        finalManyRefQuery.args.offset =
          params.pagination.page * params.pagination.perPage -
          params.pagination.perPage
        finalManyRefQuery.args.where = { [params.target]: params.id }
        finalManyRefQuery.args.where = addFilters(
          finalManyRefQuery.args.where,
          params.filter
        )
        finalManyRefQuery.args.order_by = {
          column: params.sort.field || primaryKey,
          type:
            typeof params.sort.order === 'undefined'
              ? 'asc'
              : params.sort.order.toLowerCase()
        }
        finalManyRefCountQuery.args.table = { name: tableName, schema: schema }
        finalManyRefCountQuery.args.where = {}
        finalManyRefCountQuery.args.where[primaryKey] = { $ne: null }
        finalManyRefCountQuery.args.where = addFilters(
          finalManyRefQuery.args.where,
          params.filter
        )
        finalQuery = cloneQuery(bulkQuery)
        finalQuery.args.push(finalManyRefQuery)
        finalQuery.args.push(finalManyRefCountQuery)
        break
      default:
        throw new Error(`Unsupported type ${type}`)
    }
    options.body = JSON.stringify(finalQuery)
    return { options }
  }

  const convertHTTPResponse = (response, type, resource, params) => {
    // handle errors and throw with the message
    if ('error' in response || 'code' in response) {
      throw new Error(JSON.stringify(response))
    }
    const primaryKey = getPrimaryKey(resource)

    if (primaryKey !== DEFAULT_PRIMARY_KEY) {
      if (Array.isArray(response[0])) {
        response[0].forEach((res) => {
          res[DEFAULT_PRIMARY_KEY] = res[primaryKey]
        })
      } else if (response[0]) {
        response[0][DEFAULT_PRIMARY_KEY] = response[0][primaryKey]
      } else if (response.returning[0]) {
        response.returning[0][DEFAULT_PRIMARY_KEY] =
          response.returning[0][primaryKey]
      }
    }
    switch (type) {
      case 'GET_LIST':
        return {
          data: response[0],
          total: response[1]['count']
        }
      case 'GET_ONE':
        return {
          data: response[0]
        }
      case 'CREATE':
        return {
          data: response.returning[0]
        }
      case 'UPDATE':
        return {
          data: response.returning[0]
        }
      case 'UPDATE_MANY':
        const updatedIds = response.returning.map((item) => {
          return item.id
        })

        return {
          data: updatedIds
        }
      case 'DELETE':
        return {
          data: response.returning[0]
        }
      case 'DELETE_MANY':
        const deletedIds = response.returning.map((item) => {
          return item.id
        })

        return {
          data: deletedIds
        }
      case 'GET_MANY':
        return {
          data: response[0]
        }
      case 'GET_MANY_REFERENCE':
        return {
          data: response[0],
          total: response[1].count
        }
      default:
        return { data: response }
    }
  }

  const handleFiles = async (type, resource, params) => {
    if (type === 'CREATE' || type === 'UPDATE') {
      const { data } = params

      for (const key of Object.keys(data)) {
        const value = data[key]

        if (value && value.rawFile) {
          const fileUrl = await BackendApi.uploadImage(value.rawFile)

          params = { ...params, data: { ...data, [key]: fileUrl } }
        }
      }
    }
    return params
  }

  return async (type, resource, params) => {
    params = await handleFiles(type, resource, params)

    const { options } = convertDataRequestToHTTP(type, resource, params)

    options.method = 'POST'
    if (typeof httpClient === 'function') {
      // support httpClient argument
      return httpClient(serverEndpoint + '/v1/query', options).then(
        (response) => {
          if (!isEqual(params.data, params.previousData)) {
            if (resource === 'fleet') {
              const fleet = get(response, 'json.returning[0]', null)
              if (fleet) {
                sendToExternalFleetAPI(fleet, type)
              }
            } else if (resource === 'organization') {
              const organization = get(response, 'json.returning[0]', null)
              if (organization) updateOrganizationBillingInfo(organization)
            }
          }

          return convertHTTPResponse(response.json, type, resource, params)
        }
      )
    }

    options.headers = httpClient // backwards compatible static header object
    return fetch(serverEndpoint + '/v1/query', options).then(function(
      response
    ) {
      return response.json().then((data) => {
        return convertHTTPResponse(data, type, resource, params)
      })
    })
  }
}

const updateOrganizationBillingInfo = async (organizationData) => {
  const {
    id,
    allow_chargable_operations: allowChargeableOperations
  } = organizationData
  await backendApiProvider.changeAllowChargeableOperations(
    id,
    allowChargeableOperations
  )
}

const sendToExternalFleetAPI = (fleet, type) => {
  if (type === 'CREATE') {
    const isInternal = fleet.internal
    if (!isInternal) {
      const timestamp = new Date().toISOString()
      BackendApi.createFleetCommand({
        fleetId: fleet.id,
        name: fleet.name,
        apiName: fleet.api_name,
        organizationId: fleet.organization_id || null,
        logoUrl: fleet.logoUrl || fleet.logo_url || '',
        faviconUrl: fleet.faviconUrl || fleet.favicon_url || '',
        createdAt: timestamp,
        updatedAt: timestamp
      })
    }
  }
  if (type === 'UPDATE') {
    const isInternal = fleet.internal
    if (!isInternal) {
      fleet.active
        ? BackendApi.activateFleetCommand(fleet.id)
        : BackendApi.deactivateFleetCommand(fleet.id)
      BackendApi.editFleetCommand({
        author: {
          name: 'admin'
        },
        fleetId: fleet.id,
        name: fleet.name,
        apiName: fleet.api_name,
        organizationId: fleet.organization_id || null,
        logoUrl: fleet.logoUrl || fleet.logo_url || '',
        faviconUrl: fleet.faviconUrl || fleet.favicon_url || '',
        createdAt: fleet.createdAt,
        updatedAt: fleet.updatedAt
      })
    }
  }
}
