import React from 'react'
import PropTypes from 'prop-types'
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  BooleanInput
} from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import validateExists from '../../validators/validateExists'
import ImageUploadInput from '../common/ImageUploadInput'

const FleetEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <AutocompleteReferenceInput
        source='organization_id'
        reference='organization'
        optionText='name'
        label='Organization'
      />
      <TextInput
        source='name'
        validate={[required(), validateExists(props, 'name')]}
      />
      <BooleanInput source='internal' />
      <TextInput
        source='api_name'
        disabled
        validate={[required(), validateExists(props, 'api_name')]}
      />
      <ImageUploadInput source='logo_url' label='Logo' />
      <ImageUploadInput source='favicon_url' label='Favicon' />
      <BooleanInput source='show_in_account_creation' defaultValue={false} />
      <BooleanInput source='active' />
    </SimpleForm>
  </Edit>
)

FleetEdit.propTypes = {
  resource: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
}

export default FleetEdit
