import React from 'react'
import { Edit, SimpleForm, TextInput, required } from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import validateExists from '../../validators/validateExists'

const MerchantApiKeyEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput
        source='api_key'
        validate={[required(), validateExists(props, 'api_key')]}
      />
      <TextInput source='meta' />
      <AutocompleteReferenceInput
        source='merchant_id'
        reference='merchant'
        optionText='name'
        label='Merchant'
      />
      <AutocompleteReferenceInput
        source='app_id'
        reference='app'
        optionText='name'
        label='App'
      />
    </SimpleForm>
  </Edit>
)

export default MerchantApiKeyEdit
