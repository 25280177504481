import React from 'react'
import {
  List,
  Datagrid,
  BooleanField,
  DateField,
  TextField,
  Filter,
  ReferenceField
} from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import ListActions from '../common/ListActions'

const FleetServiceList = (props) => (
  <List
    {...props}
    filters={<Filters />}
    actions={<ListActions {...props} />}
    filter={{ fleet: { internal: { _eq: true } } }}
  >
    <Datagrid rowClick='edit'>
      <ReferenceField source='fleet_id' reference='fleet'>
        <TextField source='name' />
      </ReferenceField>
      <TextField source='service_type' />
      <TextField source='vehicle_type' />
      <BooleanField source='multi_drop_supported' label='Multidrop' />
      <DateField source='created_at' />
      <DateField source='updated_at' />
    </Datagrid>
  </List>
)

const Filters = (props) => (
  <Filter {...props}>
    <AutocompleteReferenceInput
      label='Search by fleet'
      source='fleet_id'
      filter={{ internal: true }}
      reference='fleet'
      optionText='name'
      allowEmpty
      alwaysOn
    />
  </Filter>
)

export default FleetServiceList
