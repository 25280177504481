import React from 'react'
import Chip from '@material-ui/core/Chip'
import PropTypes from 'prop-types'
import { find, propEq, pipe, defaultTo, prop } from 'ramda'

const renderLabel = (key, choices) =>
  pipe(
    find(propEq('id', key)),
    defaultTo({ id: key, name: key }),
    prop('name')
  )(choices)

const VectorField = ({ record, source, choices }) => (
  <>
    {record[source] &&
      record[source].map((key) => (
        <Chip key={key} label={renderLabel(key, choices)} />
      ))}
  </>
)

VectorField.propTypes = {
  source: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  choices: PropTypes.array
}

// eslint-disable-next-line react/default-props-match-prop-types
VectorField.defaultProps = { record: {}, choices: [] }

export default VectorField
