import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  BooleanInput
} from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import validateExists from '../../validators/validateExists'
import ImageUploadInput from '../common/ImageUploadInput'

const AppEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <AutocompleteReferenceInput
        source='organization_id'
        reference='organization'
        optionText='name'
        label='Organization'
      />
      <TextInput
        source='name'
        validate={[required(), validateExists(props, 'name')]}
      />
      <BooleanInput source='show_in_account_creation' defaultValue={false} />
      <ImageUploadInput source='logo_url' label='Logo' />
    </SimpleForm>
  </Edit>
)

export default AppEdit
