/* eslint-disable  */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { showNotification, Button, SaveButton } from 'react-admin'
import IconContentAdd from '@material-ui/icons/Add'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Api from '../../backendApiProvider'

const initialState = {
  showDialog: false,
  isSubmitting: false,
  password: '',
  passwordRepeat: ''
}

class ResetPasswordButton extends Component {
  state = initialState

  showDialog = () => {
    this.setState({ showDialog: true })
  }

  closeDialog = () => {
    this.setState(initialState)
  }

  onPassowrdChange = (e) => {
    this.setState({ password: e.target.value.trim() })
  }

  onPasswordRepeatChange = (e) => {
    this.setState({ passwordRepeat: e.target.value.trim() })
  }

  submit = () => {
    const { showNotification } = this.props
    const { password, passwordRepeat } = this.state

    if (password !== passwordRepeat) {
      return showNotification('Password mismatch', 'error')
    }

    this.setState({ isSubmitting: true })

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    Api.setPassword(this.props.id, this.state.password)
      .then(() => {
        // Update the main react-admin form (in this case, the comments creation form)
        this.closeDialog()
      })
      .catch((error) => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        this.setState({ isSubmitting: false })
      })
  }

  render() {
    const { showDialog, isSubmitting } = this.state

    return (
      <Fragment>
        <Button onClick={this.showDialog} label='Set new password'>
          <IconContentAdd />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.closeDialog}
          aria-label='Set new password'
        >
          <DialogTitle>Set new password</DialogTitle>
          <DialogContent>
            <div className='ra-input'>
              <TextField
                id='new-password'
                type='password'
                variant='outlined'
                label='New password'
                disabled={isSubmitting}
                autoComplete='off'
                onChange={this.onPassowrdChange}
              />
            </div>
            <div className='ra-input'>
              <TextField
                classes=''
                id='new-password-repeat'
                type='password'
                variant='outlined'
                label='Repeat'
                disabled={isSubmitting}
                autoComplete='off'
                onChange={this.onPasswordRepeatChange}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <SaveButton
              saving={isSubmitting}
              onClick={this.submit}
              submit={this.submit}
              handleSubmitWithRedirect={this.submit}
            />
            <Button label='ra.action.cancel' onClick={this.closeDialog}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  showNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordButton)
