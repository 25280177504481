import React from 'react'
import { BooleanInput, Edit, SimpleForm } from 'react-admin'

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source='active' />
    </SimpleForm>
  </Edit>
)
