import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  Filter
} from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import ListActions from '../common/ListActions'

const MerchantApiKeyList = (props) => (
  <List
    {...props}
    filters={<Filters />}
    actions={<ListActions {...{ ...props, showCreate: false }} />}
  >
    <Datagrid rowClick='edit'>
      <TextField source='api_key' />
      <ReferenceField source='merchant_id' reference='merchant'>
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField source='app_id' reference='app'>
        <TextField source='name' />
      </ReferenceField>
      <DateField source='created_at' />
      <TextField source='revoked_at' />
    </Datagrid>
  </List>
)

const Filters = (props) => (
  <Filter {...props}>
    <AutocompleteReferenceInput
      label='Search by name'
      source='merchant_id'
      reference='merchant'
      optionText='name'
      allowEmpty
      alwaysOn
    />
  </Filter>
)

export default MerchantApiKeyList
