import { head } from 'ramda'
/**
 * We are using custom fetch, because hasura data provider support querying by id only
 *
 * @param {string} resource
 * @param {string} field
 */
const validateExists = ({ resource, id }, field) => async (value) => {
  const response = await fetch(
    `${process.env.REACT_APP_HASURA_URL || 'http://localhost:8082'}/v1/graphql`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      body: JSON.stringify({
        query: `{ ${resource}(where: {${field}: {_eq: "${value}"}}) { id ${field} } }`
      })
    }
  )

  if (!response.ok) {
    return 'Server error. Contact tech team.'
  }

  const json = await response.json()

  if (json.data[resource].length !== 0 && head(json.data[resource]).id !== id) {
    return 'Value already exists.'
  }

  return undefined
}

export default validateExists
