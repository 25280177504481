import React from 'react'
import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  required
} from 'react-admin'
import validateExists from '../../validators/validateExists'
import ImageUploadInput from '../common/ImageUploadInput'

const EditOrganization = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput
        source='name'
        validate={[required(), validateExists(props, 'name')]}
      />
      <ImageUploadInput source='logo_url' label='Logo' />
      <TextInput source='meta' />
      <BooleanInput source='enable_fleet_integration' />
      <BooleanInput source='allow_chargable_operations' />
    </SimpleForm>
  </Edit>
)

export default EditOrganization
