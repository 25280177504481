import React from 'react'
import { ImageInput, ImageField } from 'react-admin'

function format(value, fieldName) {
  return typeof value === 'string' ? { [fieldName]: value } : value
}

const ImageUploadInput = (props) => (
  <ImageInput format={format} {...props}>
    <ImageField {...props} />
  </ImageInput>
)

export default ImageUploadInput
