import React from 'react'
import { SelectInput } from 'react-admin'

export const choices = [
  { id: 'manager', name: 'Manager' },
  { id: 'creator', name: 'Creator' },
  { id: 'viewer', name: 'Viewer' }
]

const RolesInput = () => <SelectInput source='role' choices={choices} />

export default RolesInput
