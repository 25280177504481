import React from 'react'
import PropTypes from 'prop-types'
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  BooleanInput
} from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import validateExists from '../../validators/validateExists'
import ImageUploadInput from '../common/ImageUploadInput'

const FleetCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <AutocompleteReferenceInput
        source='organization_id'
        reference='organization'
        optionText='name'
        label='Organization'
      />
      <TextInput
        source='name'
        validate={[required(), validateExists(props, 'name')]}
      />
      <TextInput
        source='api_name'
        validate={[required(), validateExists(props, 'api_name')]}
      />
      <BooleanInput source='internal' defaultValue={false} />
      <ImageUploadInput source='logo_url' label='Logo' />
      <ImageUploadInput source='favicon_url' label='Favicon' />
      <BooleanInput source='show_in_account_creation' defaultValue={false} />
    </SimpleForm>
  </Create>
)

FleetCreate.propTypes = { resource: PropTypes.string.isRequired }

export default FleetCreate
