/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import {
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps
} from 'react-admin'
import { bool, func, object, string, array, number } from 'prop-types'

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  total,
  showCreate,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {showCreate && <CreateButton basePath={basePath} />}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={{ ...filterValues, ...permanentFilter }}
      exporter={exporter}
      maxResults={1000000}
    />
  </TopToolbar>
)

ListActions.defaultProps = {
  showCreate: true
}

ListActions.propTypes = {
  className: string,
  currentSort: object,
  resource: string,
  filters: object,
  displayedFilters: object,
  exporter: func,
  selectedIds: array,
  onUnselectItems: func,
  filterValues: object,
  permanentFilter: object,
  hasCreate: bool,
  basePath: string,
  showFilter: func,
  total: number,
  showCreate: bool
}

export default ListActions
