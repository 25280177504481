import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  ReferenceField,
  Filter
} from 'react-admin'
import PropTypes from 'prop-types'
import VectorField from '../VectorField'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import { choices } from './Features'
import ListActions from '../common/ListActions'

const DeactivatedField = ({ record }) => {
  if (record.deactivated_at) {
    return 'DEACTIVATED'
  }

  return ''
}

DeactivatedField.propTypes = {
  record: PropTypes.shape({ deactivated_at: PropTypes.string })
}

DeactivatedField.defaultProps = { record: {} }

const UsersList = (props) => (
  <List
    {...props}
    filters={<UsersFilter />}
    actions={<ListActions {...props} />}
  >
    <Datagrid rowClick='edit'>
      <TextField source='id' />
      <TextField source='username' />
      <EmailField source='email' />
      <VectorField source='features' label='Features' choices={choices} />
      <ReferenceField source='organization_id' reference='organization'>
        <TextField source='name' />
      </ReferenceField>
      <DeactivatedField label='Deactivated?' />
      <DateField source='created_at' />
    </Datagrid>
  </List>
)

export const UsersFilter = (props) => (
  <Filter {...props}>
    <AutocompleteReferenceInput
      label='Search by Username'
      source='id'
      reference='user'
      optionText='username'
      allowEmpty
      alwaysOn
    />
    <AutocompleteReferenceInput
      label='Search by Organization'
      source='organization_id'
      reference='organization'
      optionText='name'
      allowEmpty
      alwaysOn
    />
  </Filter>
)

export default UsersList
