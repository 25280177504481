import React from 'react'
import { BooleanInput, Create, SimpleForm } from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import ServiceTypeInput from '../common/ServiceTypeInput'
import VehicleTypeInput from '../common/VehicleTypeInput'

const FleetServiceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <AutocompleteReferenceInput
        source='fleet_id'
        reference='fleet'
        filter={{ internal: true }}
        optionText='name'
        label='Fleet'
      />
      <BooleanInput source='multi_drop_supported' />
      <ServiceTypeInput source='service_type' />
      <VehicleTypeInput source='vehicle_type' />
    </SimpleForm>
  </Create>
)

export default FleetServiceCreate
