import React from 'react'
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  required
} from 'react-admin'
import validateExists from '../../validators/validateExists'

const CreateOrganization = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='name'
        validate={[required(), validateExists(props, 'name')]}
      />
      <TextInput source='logo_url' />
      <TextInput source='meta' />
      <BooleanInput source='enable_fleet_integration' />
      <BooleanInput source='allow_chargable_operations' />
    </SimpleForm>
  </Create>
)

export default CreateOrganization
