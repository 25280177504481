import React from 'react'
import { Admin, Resource, fetchUtils } from 'react-admin'

import hasuraDataProvider from './dataProvider'
import authProvider from './authProvider'
import { FleetCreate, FleetEdit, FleetList } from './components/fleets'
import {
  FleetServiceCreate,
  FleetServiceEdit,
  FleetServiceList
} from './components/fleetServices'
import { FeatureFlagEdit, FeatureFlagList } from './components/featureFlags'
import { UsersList, EditUsers, CreateUser } from './components/users'
import {
  OrganizationList,
  EditOrganization,
  CreateOrganization
} from './components/organizations'
import {
  MerchantEdit,
  MerchantList,
  MerchantCreate
} from './components/merchants'
import Dashboard from './components/Dashboard'
import LoginPage from './components/auth/Login'
import {
  UserAppRolesList,
  UserAppRolesEdit,
  UserAppRolesCreate
} from './components/userAppRoles'
import {
  UserFleetRolesList,
  UserFleetRolesEdit,
  UserFleetRolesCreate
} from './components/userFleetRoles'
import {
  UserMerchantRolesList,
  UserMerchantRolesEdit,
  UserMerchantRolesCreate
} from './components/userMerchantRoles'
import {
  MerchantApiKeyList,
  MerchantApiKeyEdit
} from './components/merchantApiKeys'
import { AppList, AppEdit, AppCreate } from './components/apps'
import {
  MerchantOpsSettingsList,
  MerchantOpsSettingsEdit,
  MerchantOpsSettingsCreate
} from './components/merchantsOpsSettings'
import {
  UserOrganizationRoleList,
  UserOrganizationRoleEdit,
  UserOrganizationRoleCreate
} from './components/userOrganizationRoles'

const httpClient = (url, options = {}) => {
  const params = options

  if (!params.headers) {
    params.headers = new Headers({ Accept: 'application/json' })
  }
  const accessToken = localStorage.getItem('accessToken')
  options.headers.set('authorization', `Bearer ${accessToken}`)

  return fetchUtils.fetchJson(url, options)
}

const config = {
  primaryKey: {
    user_password: 'user_id',
    merchant_ops_settings: 'merchant_id',
    feature_flag: 'feature'
  }
}

const App = () => (
  <Admin
    dashboard={Dashboard}
    authProvider={authProvider}
    loginPage={LoginPage}
    dataProvider={hasuraDataProvider(
      process.env.REACT_APP_HASURA_URL || 'http://localhost:8082',
      httpClient,
      config
    )}
  >
    <Resource
      name='user'
      list={UsersList}
      edit={EditUsers}
      create={CreateUser}
    />
    <Resource
      name='organization'
      list={OrganizationList}
      edit={EditOrganization}
      create={CreateOrganization}
    />
    <Resource
      name='user_organization_role'
      list={UserOrganizationRoleList}
      edit={UserOrganizationRoleEdit}
      create={UserOrganizationRoleCreate}
    />
    <Resource
      name='merchant'
      list={MerchantList}
      edit={MerchantEdit}
      create={MerchantCreate}
    />
    <Resource
      name='merchant_api_key'
      list={MerchantApiKeyList}
      edit={MerchantApiKeyEdit}
    />
    <Resource
      name='merchant_ops_settings'
      list={MerchantOpsSettingsList}
      edit={MerchantOpsSettingsEdit}
      create={MerchantOpsSettingsCreate}
    />
    <Resource
      name='user_app_role'
      list={UserAppRolesList}
      edit={UserAppRolesEdit}
      create={UserAppRolesCreate}
    />
    <Resource
      name='user_fleet_role'
      list={UserFleetRolesList}
      edit={UserFleetRolesEdit}
      create={UserFleetRolesCreate}
    />
    <Resource
      name='user_merchant_role'
      list={UserMerchantRolesList}
      edit={UserMerchantRolesEdit}
      create={UserMerchantRolesCreate}
    />
    <Resource
      name='fleet'
      list={FleetList}
      edit={FleetEdit}
      create={FleetCreate}
    />
    <Resource
      name='fleet_service'
      list={FleetServiceList}
      edit={FleetServiceEdit}
      create={FleetServiceCreate}
    />
    <Resource name='app' list={AppList} edit={AppEdit} create={AppCreate} />
    <Resource
      name='feature_flag'
      list={FeatureFlagList}
      edit={FeatureFlagEdit}
    />
  </Admin>
)

export default App
