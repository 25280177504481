import React from 'react'
import { Edit, SimpleForm } from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import RolesInput from '../RolesInput'

const UserAppRolesEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <AutocompleteReferenceInput
        source='app_id'
        reference='app'
        optionText='name'
        label='App'
      />
      <AutocompleteReferenceInput
        source='user_id'
        reference='user'
        optionText='username'
        label='Username'
      />
      <RolesInput />
    </SimpleForm>
  </Edit>
)

export default UserAppRolesEdit
