import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  Filter,
  ImageField,
  BooleanField
} from 'react-admin'
import AutocompleteReferenceInput from '../AutocompleteReferenceInput'
import ListActions from '../common/ListActions'

const FleetList = (props) => (
  <List {...props} filters={<Filters />} actions={<ListActions {...props} />}>
    <Datagrid rowClick='edit'>
      <TextField source='id' />
      <TextField source='name' />
      <TextField source='api_name' />
      <ReferenceField source='organization_id' reference='organization'>
        <TextField source='name' />
      </ReferenceField>
      <BooleanField source='internal' />
      <BooleanField source='show_in_account_creation' />
      <ImageField source='logo_url' />
      <ImageField source='favicon_url' />
      <DateField source='created_at' />
      <DateField source='updated_at' />
    </Datagrid>
  </List>
)

const Filters = (props) => (
  <Filter {...props}>
    <AutocompleteReferenceInput
      label='Search by Organization'
      source='organization_id'
      reference='organization'
      optionText='name'
      allowEmpty
      alwaysOn
    />
    <AutocompleteReferenceInput
      label='Search by name'
      source='id'
      reference='fleet'
      optionText='name'
      allowEmpty
      alwaysOn
    />
  </Filter>
)

export default FleetList
