import React from 'react'
import { SelectArrayInput } from 'react-admin'

const choices = [
  { id: 'late_dropoff', name: 'Late Dropoff' },
  { id: 'late_pickup', name: 'Late Pickup' },
  { id: 'order_created', name: 'Order Created' }
]

const AlertsInput = () => <SelectArrayInput source='alerts' choices={choices} />

export default AlertsInput
